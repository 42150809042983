export default path => {
  if (path.substr(0, 2) === '//') {
    return path
  }

  if (path.substr(0, 4) === 'http') {
    return path
  }

  return process.env.VUE_APP_API_URL + ('/' + path).replace('//', '/')
}