<template>
  <div class="dashboard">    
    <div class="signup-body">
      <div class="container">
        <el-row :gutter="15" type="flex">
          <el-col :xs="24" :sm="8" :md="6" style="flex: 1;" class="hidden-xs">
            <div class="sticky">
              <dashboard-sidebar>
                <slot name="sidebar-after"></slot>
              </dashboard-sidebar>
            </div>
          </el-col>
          <el-col :xs="24" :sm="16" :md="18">
            <slot></slot>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
  import DashboardSidebar from '~/components/Dashboard/Sidebar'
  // import EmployerAdditionalSidebar from '~/components/Employer/AdditionalSidebar'
  import Stickyfill from 'stickyfilljs'

  export default {
    components: {
      DashboardSidebar,
      // EmployerAdditionalSidebar,
    },

    props: {
      urlPrefix: {
        default: '',
      },
    },
  mounted () {
    const elements = document.querySelectorAll('.sticky')
    Stickyfill.add(elements)
  },
    computed: {
      isEmployerRoute () {
        return this.$route.path.includes('/employer/')
      },

      isCompanyRoute () {
        return this.$route.path.includes('/freelancercompany/')
      },

      computedUrlPrefix () {
        if (this.urlPrefix) {
          return this.urlPrefix
        }

        if (this.isEmployerRoute) {
          return '/employer'
        }

        if (this.isCompanyRoute) {
          return '/freelancercompany'
        }

        return ''
      },
    },
    created() {

    }
  }
</script>

<style lang="scss">
.el-tabs__item:hover, .el-tabs__item.is-active, .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active, .el-tabs--border-card>.el-tabs__header .el-tabs__item:hover, .el-tabs--border-card>.el-tabs__header:hover .el-tabs__item:hover {
  color: #E87722 !important;
}
.router-link-exact-active.router-link-active .el-menu-item, .el-menu-item:focus, .router-link-exact-active.router-link-active .el-menu-item i {
  color: white !important;
  background-color: #E87722 !important;
}
.el-menu-item.is-active {
  color: #303133;
}
.el-menu-item:hover {
  background-color: #fef5db !important;
  color: #303133 !important;
}
.el-tabs__active-bar {
  background-color: #E87722 !important;
}
.dashboard .signup-body{
  padding-top: 0px;
}
@media (min-width: 768px) {
 .dashboard .signup-body{
    padding-top: 50px;
  } 
  .dashboard .sticky {
    position: sticky;
    top: 100px;
    width: 100%;
  }
}
@media (max-width: 767px) {
 .dashboard .signup-body{
    padding-top: 20px !important;
  } 
  .hidden-xs {
    display: none;
  }
}
</style>
