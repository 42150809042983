<template>
  <dashboard>
    <!-- <h4 class="mb-4 ml-1 bold orange-text">Jobs</h4> -->

    <el-row :gutter="15">
      <el-col :xs="24">
        <!-- <div class="back">
          <el-button type="info" class="mb-3" @click="$router.go(-1)"><i class="mr-2 el-icon-back" />Back</el-button>
        </div> -->
        <hr>
        <div class="jobview freelancer">
          <el-card>
            <div class="header">
              <center>
                <h4 class="orange-text">{{ show.title }}</h4>
                <div class="overview">
                  <!--<h5><i class="el-icon-money"></i> $1000-$2000 | <i class="el-icon-timer"></i> {{ show.job_type }}</h5>-->
                  <h5><i class="el-icon-timer" /> {{ show.job_type }}</h5>
                </div>
                <p class="mt-3 mb-0 small gray-text">Date posted: {{ show.created_at | usDateFormat }}</p>
              </center>
            </div>
            <div class="pt-0 body">
              <div class="details">
                <h5 class="mb-3 orange-text bold">Overview</h5>
                <div class="intro">
                  <p> {{ show.intro }}</p>
                </div>
                <hr class="mb-4">
                <h5 class="orange-text bold">Job Application:</h5>
                <el-form :label-position="labelPosition" class="mb-3" :model="jobApplication">
                  <el-form-item label="Subject" prop="subject">
                    <el-input v-model="jobApplication.subject" maxlength="255" show-word-limit />
                  </el-form-item>
                  <el-form-item label="Cover Letter" prop="message">
                    <el-input v-model="jobApplication.message" type="textarea" maxlength="500" show-word-limit :rows="5" />
                  </el-form-item>
                  <p class="mt-3 mb-2">Upload your resume and/or portfolio</p>
                  <el-upload
                    v-model="jobApplication.upload"
                    class="upload-demo"
                    method="POST"
                    :headers="uploadHeaders"
                    drag
                    :limit="file.limit"
                    :action="upload"
                    name="upload"
                    :before-upload="handleBeforeUpload"
                    :on-progress="handleProgress"
                    :on-success="handleSuccess"
                    :on-error="handleError"
                    :on-exceed="handleExceed"
                    :on-remove="handleRemove"
                    :multiple="false"
                  >
                    <i class="el-icon-upload" />
                    <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                    <div slot="tip" class="el-upload__tip">jpg/png/pdf files with a size less than 12MB</div>
                  </el-upload>

                  <el-alert
                    v-if="applicationErr"
                    type="error"
                    title="The following is required:"
                    :closable="click=false"
                    center="center"
                    class="application-alert"
                    @close="true"
                  >
                    <ul>
                      <li v-for="first in applicationErr.subject" :key="first">
                        <i class="el-icon-error" /> {{ first }}
                      </li>
                      <li v-for="last in applicationErr.message" :key="last">
                        <i class="el-icon-error" /> {{ last }}
                      </li>
                      <li v-for="upload in applicationErr.upload" :key="upload">
                        <i class="el-icon-error" /> {{ upload }}
                      </li>
                      <li v-if="file.validation.size">
                        <i class="el-icon-error" /> {{ file.validation.size }}
                      </li>
                      <li v-if="file.validation.limit">
                        <i class="el-icon-error" /> {{ file.validation.limit }}
                      </li>
                    </ul>
                  </el-alert>

                  <center>
                    <el-button :disabled="isApplying || isUploading" type="success" class="mt-3" @click="apply({jobApplication, f_id:f_id, job_id: tempRouteId, apply:true, job_route_id:$route.params.job_id})"><span v-if="!isApplying">Submit Application</span><span v-else>Submitting...</span></el-button>
                  </center>
                </el-form>
              </div>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </dashboard>
</template>

<script>
import moment from 'moment'
import store from '../../vuex/store'
import Dashboard from '~/components/Dashboard/Dashboard'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { JOBS, LOCAL_BASE_URL } from '../../vuex/utils/constant'
import getUser from '~/helpers/getUser'
import Model from '~/models/Model'
const USER = getUser()

export default {
  store,
  components: {
    Dashboard,
  },
  data () {
    return {
      file: {
        limit: 1,
        action: 'https://jsonplaceholder.typicode.com/posts/',
        multiple: false,
        showFileList: true,
        accept: '.jpg, .png, .pdf',
        withcredentials: false,
        fileList: [],
        validation: {
          limit: '',
          size: '',
        },
      },
      upload: LOCAL_BASE_URL + JOBS + `/apply/${parseInt(this.$route.params.job_id) - 10000}/upload`,
      labelPosition: 'top',
      jobApplication: {
        subject: '',
        message: '',
        upload: '',
      },
      click: false,
      job_id: this.$route.params.job_id,
      f_id: USER.freelancer_id,
      uploadHeaders: Model.headers(),
      tempRouteId:parseInt(this.$route.params.job_id) - 10000,
      screen: 'appliction'
    }
  },
  computed: {
    ...mapGetters({
      applicationErr: 'jobApplicationError',
      applicationSuccess: 'jobApplicationSuccess',
      show: 'getJobsDetails',
      isApplying: 'jobApplicationOnLoading',
      isUploading: 'jobApplicationOnUploading'
    }),
    hasScreeningQuestion () {
      if (this.show.questions) {
        return true
      }
      return false
    }
  },
  filters: {
    usDateFormat (value) {
        return moment(value).format('LL');
    },
  },
  mounted () {
    this.$store.commit('JOB_APPLICATION_ERROR', '')
    this.$store.dispatch('jobView', { job_id: this.tempRouteId, f_id: this.f_id })
  },
  methods: {
    ...mapActions({
      apply: 'jobApply',
    }),
    ...mapMutations(['clearApiError']),
    handleExceed () {
      this.file.validation.limit = 'Upload exceed limit must not more than one.'
    },
    handleBeforeUpload (file) {
      this.jobApplication.upload = file.name
      // File size limitation
      console.log(file.size / 1024 / 1024)
      const isLt5M = file.size / 1024 / 1024 < 12
      if (!isLt5M) {
        this.file.validation.size = 'Upload size must not more than 12 MB.'
      } else {
        this.file.validation.size = ''
      }

      // File type restriction
      // const name = file.name ? file.name : '';
      // const ext = name
      //         ? name.substr(name.lastIndexOf('.') + 1, name.length)
      //         : true;
      // const isExt = this.accept.indexOf(ext) < 0;
      // if (isExt) {
      //   this.$message.error('Please upload the correct format type');
      //   return !isExt;
      // }
    },
    handleSuccess () {
      this.$store.commit('FREELANCER_JOB_APPLICATION_ON_UPLOADING', false)
    },
    handleError () {
      this.$message.error('Please upload a JPG, PNG, or PDF file that is less than 12MB')
    },
    handleProgress () {
      this.$store.commit('FREELANCER_JOB_APPLICATION_ON_UPLOADING', true)
    },
    handleRemove () {
      this.jobApplication.upload = ''
    },

  },
}

</script>

<style lang="scss">
  .jobfeed {
    .search {
      padding: 30px;
    }
    .el-input-group__append button.el-button, .el-input-group__append, .el-input-group__prepend {
      color: #FFF;
      background-color: #E87722;
      border-color: #E87722;
    }
    .el-pagination {
      text-align: center;
    }
  }
  .jobview {
    .el-form-item {
      margin-bottom: 14px;
    }
    .el-form--label-top .el-form-item__label {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .application-alert {
      max-width: 400px;
      width: 100%;
      margin: 0 auto 25px;
      /*margin: 3% 3% 1% 25px;*/
      font-weight: bold;
      ul li {
        list-style: none;
      }
    }
    .application-alert ul{
      margin-bottom: 5px;
    }

  }
  .upload-demo {
      width: 100%;
    .el-upload, .el-upload-dragger{
      width: 100%;
    }
  }
  .el-upload-list {
    display: block;
    margin-bottom: 30px;
  }
</style>
