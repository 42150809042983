<template>
  <div>
    <!-- <div class="sidebar-mobile text-center">
      <el-row type="flex" justify="space-around">
        <el-col :span="4">
          <router-link tag="div" :to="urlPrefix + '/jobs'" class="no-underline-hover nav-btn" exact>
            <i class="el-icon-mouse mr-2"></i>Jobs
          </router-link>
        </el-col>
        <el-col :span="4">
          <router-link
            tag="div"
            :to="urlPrefix + '/conversations'"
            class="no-underline-hover nav-btn"
            exact
          >
            <i class="el-icon-chat-dot-round mr-2"></i>Messages
          </router-link>
        </el-col>
        <el-col :span="4">
          <router-link
            tag="div"
            :to="urlPrefix + '/addons'"
            class="no-underline-hover nav-btn"
            exact
          >
            <i class="el-icon-suitcase mr-2"></i>Add-on
          </router-link>
        </el-col>
        <el-col :span="4">
          <router-link
            tag="div"
            :to="urlPrefix + '/accountdetails#name-email'"
            class="no-underline-hover nav-btn"
            exact
          >
            <i class="el-icon-user-solid mr-2"></i>Profile
          </router-link>
        </el-col>
        <el-col :span="4">
          <router-link tag="div" :to="urlPrefix + '/test/skill-search'" class="no-underline-hover nav-btn" exact>
            <i class="el-icon-medal mr-2"></i>Skills
          </router-link>
        </el-col>
      </el-row>
    </div> -->
    <div class="sidebar-border">
      <div v-if="freelancer" class="user-account text-center">
        <img v-if="freelancer.profile_photo" :src="url + '/' + freelancer.profile_photo" alt="..." class="w-50" />
        <i v-else class="el-icon-plus avatar-uploader-icon" />
        <p class="mt-3 mb-1">{{ ucfirst(freelancer.first_name) }} {{ ucfirst(freelancer.last_name) }}</p>
        <p class="small mb-2">{{ freelancer.profile.title }}</p>
        <!--        <el-rate v-model="value" disabled></el-rate>-->
        <div v-if="showFrProgress">
          <p class="small gray-text mb-0 mt-3">
            Your profile is {{ fprogress }}% complete
            <el-popover placement="right" width="200" trigger="hover" class="freelancer-popover">
              <i class="el-icon-success" :class="freelancerProgress['profile_photo'] && 'complete'" />
              Profile Photo
              <br />
              <i class="el-icon-success" :class="freelancerProgress['skills'] && 'complete'" />
              Skills
              <br />
              <i class="el-icon-success" :class="freelancerProgress['bio'] && 'complete'" />
              Bio
              <br />
              <!-- <i class="el-icon-success"></i> Experience <br> -->
              <i class="el-icon-success" :class="freelancerProgress['price_ava'].ava && freelancerProgress['price_ava'].price && 'complete'" />
              Price and Availability
              <br />
              <i slot="reference" class="el-icon-info" />
            </el-popover>
          </p>

          <el-progress :percentage="fprogress" :color="'#e87722'" :format="format" class="freelancer-bar" />
        </div>
        <router-link tag="button" to="/my-profile" class="el-button el-button--success el-button--mini mt-3">
          View My Profile
        </router-link>
      </div>

      <div v-if="company.information" class="user-account text-center">
        <img v-if="company.information.logo" :src="url + '/' + company.information.logo" alt="..." class="w-50" />
        <i v-else class="el-icon-plus avatar-uploader-icon" />
        <p class="mt-3 mb-1">{{ ucfirst(company.first_name) }} {{ ucfirst(company.last_name) }}</p>
        <p class="small mb-2">{{ company.information.company_name }}</p>
        <el-rate v-model="value" disabled />
      </div>

      <div v-if="employer.company" class="user-account text-center">
        <div v-if="employer.company.logo" class="image-card mx-auto" style="width:100px; height: 100px;">
          <div class="image-border">
            <div class="image-content" :style="`background-image: url('${companyLogo(employer.company)}');`">
            </div>
          </div>
        </div>
        <router-link v-else tag="i" to="/employer/companyinformation" class="pointer el-icon-plus avatar-uploader-icon" />
        <p class="mt-3 mb-1">{{ ucfirst(employer.first_name) }} {{ employer.last_name }}</p>
        <p class="small mb-2">{{ ucfirst(employer.company.company_name) }}</p>
        <div v-if="showEmpProgress">
          <p class="small gray-text mb-0 mt-3">
            Your profile is {{ progress }}% complete
            <el-popover placement="right" width="200" trigger="hover" class="freelancer-popover">
              <i class="el-icon-success" :class="empProgress['company_name'] && 'complete'" />
              Company Name
              <br />
              <i class="el-icon-success" :class="empProgress['company_url'] && 'complete'" />
              Company URL
              <br />
              <i class="el-icon-success" :class="empProgress['logo'] && 'complete'" />
              Company Logo
              <br />
              <i class="el-icon-success" :class="empProgress['description'] && 'complete'" />
              Company Description
              <br />
              <i slot="reference" class="el-icon-info" />
            </el-popover>
          </p>

          <el-progress :percentage="progress" :color="'#e87722'" :format="format" class="freelancer-bar" />
        </div>
      </div>
      <el-menu class="el-menu-vertical-demo">
        <router-link :to="urlPrefix + '/feed'" class="no-underline-hover" exact>
          <el-menu-item index="1">
            <i class="el-icon-s-home mr-2" />
            Dashboard
          </el-menu-item>
        </router-link>
        <router-link :to="isFreelancer ? '/freelancer/jobs' : urlPrefix + '/jobs'" class="no-underline-hover" exact>
          <el-menu-item index="2">
            <i class="el-icon-mouse mr-2" />
            <span v-text="isFreelancer ? 'Job Search' : 'Jobs'" />
          </el-menu-item>
        </router-link>
        <router-link v-if="isFreelancer" to="/freelancer/jobs/applied" class="no-underline-hover" exact>
          <el-menu-item index="2">
            <i class="el-icon-s-cooperation mr-2" />
            My Job Applications
          </el-menu-item>
        </router-link>
        <router-link v-if="isFreelancer" to="/freelancer/jobs/bookmarked" class="no-underline-hover" exact>
          <el-menu-item index="2">
            <i class="el-icon-star-off mr-2" />
            My Bookmarked Jobs
          </el-menu-item>
        </router-link>

        <!-- Need to add logic so this shows up only to Employers and Freelancer company -->
        <!-- <router-link :to="urlPrefix + '/freelancers'" class="no-underline-hover" exact> -->

        <router-link v-if="isCompany" :to="urlPrefix + '/freelancers'" class="no-underline-hover" exact>
          <el-menu-item index="9">
            <i class="el-icon-user" />
            Freelancers
          </el-menu-item>
        </router-link>

        <router-link to="/conversations" class="no-underline-hover" exact>
          <el-menu-item index="3">
            <i class="el-icon-chat-dot-round mr-2" />
            Messages
            <span v-if="numUnreadConversations" class="badge badge-danger badge-pill" v-text="numUnreadConversations" />
          </el-menu-item>
        </router-link>
        <!-- <router-link :to="urlPrefix + '/addons'" class="no-underline-hover" exact>
          <el-menu-item index="4">
            <i class="el-icon-suitcase mr-2"></i>Add-ons
          </el-menu-item>
        </router-link> -->
        <router-link v-if="isCompany" :to="urlPrefix + '/billing'" class="no-underline-hover" exact>
          <el-menu-item index="5">
            <i class="el-icon-s-finance mr-2" />
            Billing
          </el-menu-item>
        </router-link>
        <router-link v-if="isCompany" :to="urlPrefix + '/transactions'" class="no-underline-hover" exact>
          <el-menu-item index="5">
            <i class="el-icon-document-copy mr-2" />
            Transactions
          </el-menu-item>
        </router-link>
        <hr />
        <router-link :to="urlPrefix + '/accountdetails'" class="no-underline-hover" exact>
          <el-menu-item index="6">
            <i class="el-icon-user-solid mr-2" />
            My Profile
          </el-menu-item>
        </router-link>
        <router-link v-if="isFreelancer" :to="urlPrefix + '/my-skills/skill-search'" class="no-underline-hover" exact>
          <el-menu-item index="6">
            <i class="el-icon-medal mr-2" />
            My Skills
          </el-menu-item>
        </router-link>
        <router-link v-if="isCompany" :to="urlPrefix + '/companyinformation'" class="no-underline-hover" exact>
          <el-menu-item index="7">
            <i class="el-icon-office-building mr-2" />
            Company Profile
          </el-menu-item>
        </router-link>
        <router-link v-if="isCompany" :to="urlPrefix + '/bookmarked-freelancers'" class="no-underline-hover" exact>
          <el-menu-item index="9">
            <i class="el-icon-office-building mr-2" />
            Bookmarked
          </el-menu-item>
        </router-link>
        <slot />
      </el-menu>
    </div>
  </div>
</template>

<script>
import store from '../../vuex/store';
import { mapGetters, mapActions } from 'vuex';
import { LOCAL_BASE_URL } from '../../vuex/utils/constant';
import _ from 'lodash';
import getUserType from '~/helpers/getUserType';
import getUser from '~/helpers/getUser';
import showsCompanyLogo from '~/mixins/showsCompanyLogo'
const USER = getUser();

export default {
  store,

  mixins: [ showsCompanyLogo ],

  data() {
    return {
      value: 5,
      visible: false,
      photo: '/images/profile.jpg',
      profile: {},
      userType: getUserType(),
      url: LOCAL_BASE_URL,
      notifications: [],
      showEmpProgress: true,
      showFrProgress: true
      // employer: {},
    };
  },

  computed: {
    ...mapGetters({
      company: 'getFreelancerCompanyInfo',
      freelancer: 'getFreelancerInfo',
      employer: 'getEmployerInfo',
      numUnreadConversations: 'messenger/numUnread'
    }),

    urlPrefix() {
      const userType = getUserType();
      if (userType === 'freelancer') {
        return '';
      }

      return '/' + userType;
    },

    isCompany() {
      return ['employer', 'freelancercompany'].includes(this.userType);
    },

    isFreelancer() {
      return !this.isCompany;
    },

    // quick workaround so I can continue working on frontend
    user() {
      return {
        first_name: 'John',
        last_name: 'Doe',
        profile: {
          title: 'CEO'
        }
      };
    },
    empProgress() {
      return {
        company_name: this.employer.company.company_name,
        company_url: this.employer.company.company_url,
        logo: this.employer.company.logo,
        description: this.employer.company.description
      };
    },

    freelancerProgress() {
      return {
        profile_photo: this.freelancer.profile_photo,
        skills: this.freelancer && this.freelancer.freelancer_skills && this.freelancer.freelancer_skills.length ? this.freelancer.freelancer_skills.length : this.freelancer.proposed_skills && this.freelancer.proposed_skills.length ? this.freelancer.proposed_skills.length : 0,
        bio: this.freelancer.profile ? this.freelancer.profile.description : '',
        price_ava: {
          price: (this.freelancer.profile && this.freelancer.profile.full_time_price && this.freelancer.profile.full_time_price !== '0.00' && this.freelancer.profile.full_time_price !== '0.0' && this.freelancer.profile.full_time_price !== '0.' && this.freelancer.profile.full_time_price !== '0') || (this.freelancer.profile && this.freelancer.profile.part_time_price && this.freelancer.profile.part_time_price !== '0.00' && this.freelancer.profile.part_time_price !== '0.0' && this.freelancer.profile.part_time_price !== '0.' && this.freelancer.profile.part_time_price !== '0') || (this.freelancer.profile && this.freelancer.profile.hourly_price && this.freelancer.profile.hourly_price !== '0.00' && this.freelancer.profile.hourly_price !== '0.0' && this.freelancer.profile.hourly_price !== '0.' && this.freelancer.profile.hourly_price !== '0'),
          ava: this.freelancer.profile ? this.freelancer.profile.full_time_ava || this.freelancer.profile.part_time_ava || this.freelancer.profile.hourly_ava : false
        }
      };
    },

    fprogress() {
      let p = [];
      if (this.freelancerProgress.profile_photo) {
        p = [...p, 25];
      }
      if (this.freelancerProgress.skills) {
        p = [...p, 25];
      }
      if (this.freelancerProgress.bio) {
        p = [...p, 25];
      }
      if (this.freelancerProgress.price_ava.price && this.freelancerProgress.price_ava.ava) {
        p = [...p, 25];
      }
      return p.reduce((a, b) => a + b, 0);
    },

    progress() {
      let p = [];
      if (this.empProgress.company_name) {
        p = [...p, 25];
      }
      if (this.empProgress.company_url) {
        p = [...p, 25];
      }
      if (this.empProgress.logo) {
        p = [...p, 25];
      }
      if (this.empProgress.description) {
        p = [...p, 25];
      }
      return p.reduce((a, b) => a + b, 0);
    }

    // TODO: Please Fix This
    // ...mapState('USER', {
    //     user: 'details'
    // })
  },

  watch: {
    user(data) {
      if (data) {
        this.profile = data;
        if (data.profile_photo === '') {
          this.profile.profile_photo = this.photo;
        }
      }
    },
    fprogress: {
      handler(p) {
        if (parseInt(p) === 100) {
          this.showFrProgress = false;
        } else {
          this.showFrProgress = true;
        }
        this.updateProgress(this.showFrProgress);
      },
      deep: true,
      immediate: true
    },
    progress: {
      handler(p) {
        if (parseInt(p) === 100) {
          this.showEmpProgress = false;
        } else {
          this.showEmpProgress = true;
        }
      },
      deep: true,
      immediate: true
    }
  },

  async created() {
    this.$store.dispatch('messenger/initialize');
  },

  mounted() {
    if (USER) {
      switch (USER.provider) {
        case 'freelancer':
          this.$store.dispatch('freelancerGet');
          break;
        case 'company':
          this.$store.dispatch('freelancerCompanyGet');
          break;
        case 'employer':
          this.$store.dispatch('employerGet');
          break;
      }
    }
  },

  methods: {
    format(percentage) {
      return percentage === 20 ? '' : ''
    },

    ucfirst(string) {
      return string.split(' ').map(word => _.capitalize(word)).join(' ')
    },

    ...mapActions({
      updateProgress: 'setFreelancerProfileProgress'
    })
  }
};
</script>

<style lang="scss" scoped>
.sidebar-mobile {
  margin-bottom: 15px;

  button {
    padding: 15px;
  }
}

.sidebar-border {
  .el-menu-item-group__title {
    padding-top: 0;
  }
}
i.complete.el-icon-success {
  color: #e87722;
}
i.el-icon-success {
  color: lightgray;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .sidebar-border {
    display: none;
  }
  .sidebar-mobile {
    font-size: 12px;
    text-align: center;
    padding: 7px 0px;
    background-color: gray;
    margin: 0 -15px 25px;
    -webkit-box-shadow: #979797 0 2px 4px 0;
    box-shadow: #979797 0 2px 4px 0;
  }
  .sidebar-mobile i {
    display: block;
    width: 100%;
    margin-bottom: 2px;
    font-size: 25px;
  }
  .nav-btn {
    // border: 2px solid white;
    padding: 7px 3px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
  }
  .nav-btn:hover {
    background-color: #e87722;
    cursor: pointer;
  }
}
@media (min-width: 768px) {
  .sidebar-mobile {
    display: none;
  }
}
</style>
